/*Input*/
.input-checkbox {
  align-items: center;
  cursor: pointer;

  @extend .font-subtitle-medium-14;
  color: $color-dark-text-secundary;

  border-radius: 4px;

  &.square {
    background-color: $color-background-overlay-5;
    border-radius: 0.5rem;
    align-self: center;

    .form-check-input {
      cursor: pointer;
      background-color: transparent;
      padding: 0.75rem;
      margin: 20%;
      place-self: center;

      border: 2px solid $color-dark-text-secundary;
      border-radius: 50%;

      &:checked {
        border: 2px solid $color-primary;
      }
    }
  }

  &:hover {
    background-color: $color-background-overlay-12;
  }

  &.selected {
    background-color: $color-background-selected;
    color: $color-primary;
  }
}

/*Tooltip*/
::ng-deep .tooltipCustom {
  background: $color-background-gray;
  box-shadow: 0 3px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 0.5px 0 rgba(0, 0, 0, 0.08),
    0 5px 12.5px 7.5px rgba(0, 0, 0, 0.04);
  color: $color-dark-text-secundary;

  font-family: Nunito;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;

  padding: 0.5rem;
  width: 11rem;
}

:host ::ng-deep .mat-ripple-element {
  z-index: -1;
  background-color: $color-dark-button-primary-active;
}

.dark-btn {
  &:hover:not([disabled]) {
    background-color: $color-dark-button-hover !important;
  }

  &:focus-visible {
    border: 1px solid $color-dark-button-secundary-border;
    box-shadow: 0px 0px 2px 2px $color-primary-highlight-button;
  }

  &:disabled {
    background-color: $color-background-overlay-5;
  }
}

.primary-btn {
  &:hover {
    background-color: $color-button-hover-primary !important;
  }

  &:focus-visible {
    border: 1px solid $color-dark-button-primary-border;
    box-shadow: 0px 0px 5px 5px $color-primary-highlight-button;
  }

  // quando uma sugestão no input é selecionada
  input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #414141 inset;
    -webkit-box-shadow: 0 0 0px 1000px #414141 inset;
    -webkit-text-fill-color: $color-dark-text-secundary;
  }
}

// Remove scroll de input type="number"
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
