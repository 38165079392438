/* Nunito */
/*Titles*/
.font-title-regular {
  font: 24px "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
}
.font-title-medium {
  font: 20px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0.15px;
}
/*Subtitles*/
.font-subtitle-regular {
  font: 16px "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0.15px;
}
.font-subtitle-medium {
  font: 16px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0.15px;
}
.font-subtitle-bold {
  font: 16px "Nunito", sans-serif;
  font-weight: 700;
  letter-spacing: 0.15px;
}
.font-subtitle-medium-14 {
  font: 14px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0.1px;
}
/*Bodys*/
.font-body-regular {
  font: 16px "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.font-body-regular-14 {
  font: 14px "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0.25px;
}
/*Buttons*/
.font-button-medium {
  font: 14px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0.25px;
}
/*Caption*/
.font-caption-regular {
  font: 12px "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
}
.font-caption-medium {
  font: 12px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
}
/*Overline*/
.font-overline-medium {
  font: 10px "Nunito", sans-serif;
  font-weight: 600;
  letter-spacing: 0.8px;
}
/*Bold*/

/* ************************ */
