/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./assets/scss/colors";
@import "./assets/scss/fonts";
@import "./assets/scss/common";

html,
body {
  min-height: 100vh;
  height: auto;
  margin: 0;
  background-color: $color-background-backoffice;
}

html,
body {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
  background: $color-background-overlay-5;
  border-radius: 4.8px;
}

::-webkit-scrollbar-thumb {
  background: $color-background-overlay-9;
  height: 7.25rem;
  border-radius: 4.8px;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-background-overlay-12;
}
//.mat-drawer-inner-container{ overflow: hidden !important; }
