/*  COLORS */

/* Default Colors*/
/*Primary*/
$color-primary: #f69116;
/*Icons*/
$color-icon-primary: #f69116;
/*Background*/
$color-background-primary: #f69116;
$color-background-primary-20: #f6911633;
/*Text*/
$color-text-spotlight: #f69116;
$color-text-light-7: rgba(255, 255, 255, 0.7);
/*Buttons*/
$color-button-primary: #f69116;
$color-button-active-primary: #E08617;
$color-button-hover-primary: #F1B257;
$color-primary-highlight-button: rgba(246, 145, 22, 0.2);
/*Dividers*/
$color-divider-primary: #f69116;
/*Categories*/
$color-category-butcher-and-fishmonger: #e65c5c; /*Açougue e peixaria*/
$color-category-market-and-utilities: #9fcc5e; /*Bazar e utilidades*/
$color-category-drinks: #9a6658; /*Bebidas*/
$color-category-fuel: #78909c; /*Combustível*/
$color-category-child: #ed698b; /*Infantil*/
$color-category-cold-cuts-and-frozen: #68a6f0; /*Frios e congelados*/
$color-category-fruits-and-vegetables: #47ac75; /*Hortifruti*/
$color-category-dairy: #f0c763; /*Laticínios*/
$color-category-cleaning-products: #62c0dd; /*Limpeza*/
$color-category-grocery: #f1885a; /*Mercearia*/
$color-category-perfumery-and-beauty: #5ec8b0; /*Perfumaria e beleza*/
$color-category-pet: #b377e3; /*Pet*/
$color-category-medicines: #7278e7; /*Remédios*/
/* ************************ */

/* Light Theme*/
/*Primary*/
$color-light-primary-light: #fcf2e1;
/*Icons*/
$color-icon-gray: #666666;
$color-light-icon-white: #ffffff;
/*Background*/
$color-light-background-primary-light: #fcf2e1;
$color-light-background-divider: #f5f5f5;
$color-light-background-divider-secundary: #0000001e;
$color-background-white: #ffffff;
$color-background-primary-2: #f6911633; /* branco 20%*/
$color-light-background-primary: #0000000c; /*branco 7%*/
$color-light-background-overlay-9: #00000017; /*branco 9%*/
/*Text*/
$color-light-text-primary: #191919;
$color-light-text-secundary: #666666;
$color-light-text-terciary: #757575;
$color-light-text-helper: #7f7f7f;
$color-light-text-disabled: #a8a8a8;
$color-light-text-title: #000000e6;
$color-text-white: #ffffff;
$color-light-text-products: #00000099;
$color-text-low-price: #7eae38;
$color-text-med-price: #968f7d;
$color-text-high-price: #e23939;
/*Buttons*/
$color-light-button-secundary: #ffffff;
$color-light-button-secundary-border: #f69116;
$color-light-button-3: #00000007; /*branco 3%*/
$color-light-button-7: #0000000c; /*branco 7%*/
$color-light-button-9: #ffffff17; /*branco 9%*/
/*Dividers*/
$color-light-divider-grey: #e0e0e0;
/*Warnings*/
$color-light-warning-success: #7cb342;
$color-light-warning-error: #c84646;

/* Dark Theme*/
/*Primary*/
$color-dark-primary-light: #fbba54;
/*Icons*/
$color-dark-icon-white-primary: #ffffffe7; /* branco 90%*/
$color-dark-icon-white-secundary: #ffffffb3; /*branco 70%*/
$color-dark-icon-gray: #c2c2c2;
/*Background*/
$color-dark-background-primary-light-9: #f6911617; /* branco 9%*/
$color-dark-background-primary-light-20: #f6911633; /* branco 20%*/
$color-dark-background-divider: #ffffff1f; /*branco 12%*/
$color-background-gray: #333333;
$color-background-gray-2: #3D3D3D;
$color-background-light-grey: #444444;
$color-background-dark-gray: #121212;
$color-background-overlay-3: #ffffff08; /*branco 3%*/
$color-background-overlay-5: #ffffff0d; /*branco 5%*/
$color-background-overlay-7: #ffffff11; /*branco 7%*/
$color-background-overlay-9: #ffffff17; /*branco 9%*/
$color-background-overlay-12: #ffffff1f; /*branco 12%*/
$color-background-overlay-50: #ffffff80; /*branco 50%*/
$color-background-backoffice: #2c2c2c;
$color-background-dark-03: #383838; /*branco opaco 3%*/
$color-background-dark-05: #484848; /*branco opaco 5%*/
$color-background-dark-21: #3f3f3f; /*branco opaco 21%*/

$color-background-selected: rgba($color-primary, .2); /*primary 02%*/
/*Text*/
$color-dark-text-primary: #ffffffe7; /*branco 90%*/
$color-dark-text-secundary: #ffffffb2; /*branco 70%*/
$color-dark-text-terciary: #ffffff7e; /*branco 50%*/
$color-dark-text-helper: #ffffff80; /*branco 50%*/
$color-dark-text-disabled: #ffffff66; /*branco 40%*/
$color-text-dark-grey: #121212;
$color-dark-text-title: #ffffffe6;
/*Não possui cores referentes aos preços*/
/*Buttons*/
$color-dark-button-secundary-one: #121212;
$color-dark-button-secundary-two: #333333;
$color-dark-button-secundary-border: #f69116;
$color-dark-button-highlight-2: #f6911633; /* highlight 20%*/
$color-dark-button-5: #ffffff0c;
/* Inout borders and highlighters */
$color-dark-input-border:  #ffffff1f; /* branco 12% */
$color-dark-highlighter-input: rgba(246, 145, 22, 0.5);
/* Buttons animation */
$color-dark-button-primary-active: #ffffff08;
$color-dark-button-hover: #ffffff1f;
$color-dark-button-primary-border: #ffffffe7;

/*Dividers*/
$color-dark-divider-primary: #ffffff1e;
$color-dark-divider-grey: #414141;
/*Warnings*/
$color-dark-warning-success: #81c784;
$color-dark-warning-error: #e57373;
